import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube, faNetworkWired, faServer } from '@fortawesome/free-solid-svg-icons';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import './KubernetesSimulator.css';
import Terminal from './Terminal';

// Génération de noms random
const generateRandomName = (type) => {
    const adjectives = ['Fast', 'Dynamic', 'Bright', 'Sharp', 'Bold'];
    const nouns = ['Falcon', 'Tiger', 'Phoenix', 'Eagle', 'Lion'];
    const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
    const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];
    return `${type}-${randomAdjective}-${randomNoun}`;
};

// Enregistrement des Charts générés
Chart.register(...registerables);

const KubernetesSimulator = () => {
    const [pods, setPods] = useState([]);
    const [services, setServices] = useState([]);
    const [deployments, setDeployments] = useState([]);
    const [yamlOutput, setYamlOutput] = useState(''); // Contenu YAML généré
    const [message, setMessage] = useState('');
    const [progress, setProgress] = useState(0);

    // Génération de structure YAML
    const generateYAML = (type, name) => {
        let yaml = `---
apiVersion: v1
kind: ${type}
metadata:
  name: ${name}
spec:
  `;
        if (type === 'Pod') {
            yaml += `
  containers:
    - name: ${name}-container
      image: nginx
      ports:
        - containerPort: 80`;
        } else if (type === 'Service') {
            yaml += `
  ports:
    - protocol: TCP
      port: 80
  selector:
    app: ${name}`;
        } else if (type === 'Deployment') {
            yaml += `
  replicas: 3
  selector:
    matchLabels:
      app: ${name}
  template:
    metadata:
      labels:
        app: ${name}
    spec:
      containers:
        - name: ${name}-container
          image: nginx
          ports:
            - containerPort: 80`;
        }
        return yaml;
    };

    const createPod = () => {
        simulateDeployment('Pod', setPods, pods);
    };

    const createService = () => {
        simulateDeployment('Service', setServices, services);
    };

    const createDeployment = () => {
        simulateDeployment('Deployment', setDeployments, deployments);
    };

    const simulateDeployment = (type, setFunction, items) => {
        setMessage(`Creating ${type}...`);
        setProgress(0);
        const interval = setInterval(() => {
            setProgress((prev) => {
                if (prev >= 100) {
                    clearInterval(interval);
                    setMessage(`${type} created successfully!`);
                    const newName = generateRandomName(type);
                    setFunction([...items, newName]);

                    // Générer et ajouter du YAML
                    const newYAML = generateYAML(type, newName);
                    setYamlOutput((prevYaml) => prevYaml + '\n' + newYAML);

                    return prev;
                }
                return prev + 10;
            });
        }, 200);
    };

    const data = {
        labels: ['Pods', 'Services', 'Deployments'],
        datasets: [
            {
                label: 'Resource Monitoring',
                backgroundColor: ['#10b981', '#facc15', '#f97316'],
                data: [pods.length, services.length, deployments.length],
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                max: 10,
                ticks: {
                    fontSize: 16,
                    color: 'white',
                },
            },
            x: {
                ticks: {
                    fontSize: 16,
                    color: 'white',
                },
            },
        },
    };

    return (
        <div className="k8s-simulator-container">
            <h2>Kubernetes Simulator</h2>
            <div className="btn-container">
                <button className="btn-icon" onClick={createPod}>
                    <FontAwesomeIcon icon={faCube} /> Create Pod
                </button>
                <button className="btn-icon" onClick={createService}>
                    <FontAwesomeIcon icon={faNetworkWired} /> Create Service
                </button>
                <button className="btn-icon" onClick={createDeployment}>
                    <FontAwesomeIcon icon={faServer} /> Create Deployment
                </button>
            </div>

            {/* Barre de progression */}
            {progress > 0 && progress < 100 && (
                <div className="progress-bar">
                    <div className="progress-fill" style={{ width: `${progress}%` }}></div>
                </div>
            )}

            {/* Affichage du message */}
            {message && <div className="notification">{message}</div>}

            <div className="simulator-content">
                {/* Graphique */}
                <div style={{ width: '50%' }}>
                    <Bar data={data} options={options} />
                </div>

                {/* Terminal avec le YAML */}
                <div style={{ width: '40%' }}>
                    <Terminal output={yamlOutput} />
                </div>
            </div>
        </div>
    );
};

export default KubernetesSimulator;
