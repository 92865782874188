import React from 'react';
import './Terminal.css';

const Terminal = ({ output }) => {
    return (
        <div className="terminal">
            <pre>{output}</pre>
        </div>
    );
};

export default Terminal;
