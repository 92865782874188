import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-white py-6">
            <div className="container mx-auto text-center">
                {/* Copyright */}
                <p className="text-sm">© 2024 Alexis BERNE. All rights reserved.</p>

                {/* Social media icons (optional) */}
                <div className="flex justify-center space-x-4 mt-4">
                    <a href="https://gitlab.com/bernealexis" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                        <i className="fab fa-github"></i> GitHub
                    </a>
                    <a href="https://www.linkedin.com/in/alexis-berne-626558128/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                        <i className="fab fa-linkedin"></i> LinkedIn
                    </a>
                    <a href="mailto:bernealexis@gmail.com" className="text-gray-400 hover:text-white">
                        <i className="fas fa-envelope"></i> Email
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
