import React from 'react';

const About = () => {
    return (
        <section id="about" className="min-h-screen py-16 bg-gray-100">
            <div className="container mx-auto flex flex-col items-center text-center">
                {/* Titre et Description */}
                <h2 className="text-4xl font-bold mb-4">Qui suis-je ?</h2>
                <p className="text-lg text-gray-600 mb-8 max-w-xl">
                    Je suis un ingénieur DevOps passionné, animé par un profond sentiment pour les technologies cloud. Je me concentre sur la création de systèmes évolutifs et résilients en utilisant des outils modernes comme Kubernetes, Terraform et AWS.
                </p>

                {/* Conteneur pour le texte et l'image */}
                <div className="flex flex-col md:flex-row items-center justify-center mt-8">
                    {/* Compétences à gauche */}
                    <div className="flex-1 text-center md:text-left">
                        <p className="text-lg font-semibold mb-4">Docker</p>
                        <p className="text-lg font-semibold mb-4">Kubernetes</p>
                        <p className="text-lg font-semibold mb-4">Python</p>
                        <p className="text-lg font-semibold mb-4">AWS</p>
                    </div>

                    {/* Image à droite */}
                    <div className="flex-1 flex justify-center mt-8 md:mt-0 md:ml-12">
                        <img
                            src="images/moi PP 1.jpg"
                            alt="Alexis Berne"
                            className="rounded-full w-48 h-48 object-cover shadow-lg"
                        />
                    </div>
                </div>

                {/* Citation Inspirante */}
                <div className="text-center mt-12">
                    <blockquote className="italic text-gray-600">
                        "L'automatisation n'est pas seulement une question d'efficacité, c'est une question de libération de créativité." – Alexis Berne
                    </blockquote>
                </div>

                {/* Appel à l'Action */}
                <div className="text-center mt-12">
                    <a href="#contact" className="bg-blue-600 text-white py-3 px-6 rounded-full hover:bg-blue-700 transition-all">
                        Contactez-moi
                    </a>
                </div>
            </div>
        </section>
    );
};

export default About;
