import React from "react";
import emailjs from 'emailjs-com';

const Contact = () => {
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            'service_05md8vd',
            'template_y4dqa0q',
            e.target,
            'np5qan4qOOPEmREsM'
        )
            .then((result) => {
                console.log(result.text);
                alert("Message envoyé avec succès !");
            }, (error) => {
                console.log(error.text);
                alert("Erreur lors de l'envoi du message.");
            });
    };

    return (
        <section id="contact" className="py-16 bg-gray-100">
            <div className="container mx-auto">
                <h2 className="text-4xl font-bold text-center mb-12">Let's chat!</h2>
                <form onSubmit={sendEmail} className="w-full max-w-lg mx-auto bg-white p-8 rounded-lg shadow-md">
                    <div className="mb-4">
                        <input
                            className="w-full bg-gray-50 p-4 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-600 focus:outline-none"
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            className="w-full bg-gray-50 p-4 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-600 focus:outline-none"
                            type="email"
                            name="email"
                            placeholder="Your Email"
                            required
                        />
                    </div>
                    <div className="mb-4">
            <textarea
                className="w-full bg-gray-50 p-4 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-600 focus:outline-none"
                rows="6"
                name="message"
                placeholder="Your Message"
                required
            ></textarea>
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition-all"
                    >
                        Send Message
                    </button>
                </form>
            </div>
        </section>
    );
};

export default Contact;
