import React, { useState } from 'react';

const BurgerMenu = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <button className="burger-icon" onClick={toggleMenu}>
                <span className="block w-6 h-0.5 bg-white mb-1"></span>
                <span className="block w-6 h-0.5 bg-white mb-1"></span>
                <span className="block w-6 h-0.5 bg-white"></span>
            </button>
            {isOpen && (
                <div className="mobile-menu">
                    <a href="#home" className="block px-4 py-2 text-white">Home</a>
                    <a href="#about" className="block px-4 py-2 text-white">About</a>
                    <a href="#skills" className="block px-4 py-2 text-white">Skills</a>
                    <a href="#work" className="block px-4 py-2 text-white">Work</a>
                    <a href="#contact" className="block px-4 py-2 text-white">Contact</a>
                </div>
            )}
        </div>
    );
};

export default BurgerMenu;
