import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Skills from './components/Skills';
import Work from './components/Work';
import Contact from './components/Contact';
import About from './components/About';
import Home from './components/Home';
import './index.css'

function App() {
    return (
        <div>
            <Navbar />

            {/* Section Home */}
            <Home />

            {/* Section About */}
            <About />

            {/* Section Skills */}
            <Skills />

            {/* Section Work */}
            <Work />

            {/* Section Contact */}
            <Contact />

            {/* Footer */}
            <Footer />
        </div>
    );
}

export default App;
