import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Import AOS styles
import 'aos/dist/aos.css';
import AOS from 'aos';

// Initialiser AOS
AOS.init({
    duration: 1200,
    once: true,
});

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();

