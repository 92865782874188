import React from 'react';
import BurgerMenu from './BurgerMenu';
import './Navbar.css';

const Navbar = () => {
    return (
        <nav className="bg-gray-800 text-white fixed w-full top-0 left-0 z-10 shadow-lg">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between h-16">
                <div className="flex items-center">
                    <h1 className="text-2xl font-bold">Alexis Berne</h1>
                </div>
                <div className="hidden md:flex space-x-4 items-center">
                    <a href="#home" className="text-white hover:text-blue-400 px-3 py-2 rounded-md text-lg font-medium">Home</a>
                    <a href="#about" className="text-white hover:text-blue-400 px-3 py-2 rounded-md text-lg font-medium">About</a>
                    <a href="#skills" className="text-white hover:text-blue-400 px-3 py-2 rounded-md text-lg font-medium">Skills</a>
                    <a href="#work" className="text-white hover:text-blue-400 px-3 py-2 rounded-md text-lg font-medium">Work</a>
                    <a href="#contact" className="text-white hover:text-blue-400 px-3 py-2 rounded-md text-lg font-medium">Contact</a>
                </div>

                {/* Burger menu visible uniquement sur mobile */}
                <div className="md:hidden">
                    <BurgerMenu />
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
