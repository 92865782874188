import React from 'react';
import KubernetesSimulator from './KubernetesSimulator';

const Home = () => {
    return (
        <section id="home" className="relative h-screen bg-blue-500 flex flex-col justify-center items-center">
            <h1 className="text-5xl font-bold text-white z-10">Bienvenue!</h1>
            <KubernetesSimulator /> {/* Ajout de l'animation */}
        </section>
    );
};

export default Home;
