import React, { useState } from 'react';
import { FaGithub, FaAws, FaDocker, FaReact, FaPython, FaLinux } from 'react-icons/fa';
import { SiKubernetes, SiTerraform, SiAnsible, SiPrometheus, SiGrafana, SiGitlab, SiMysql, SiMongodb } from 'react-icons/si';

const Skills = () => {
    // Utiliser un état local pour gérer le survol des expériences
    const [hoveredExperience, setHoveredExperience] = useState(null);

    return (
        <section id="skills" className="py-16 bg-white min-h-screen">
            <div className="container mx-auto">
                <h2 className="text-4xl font-bold text-center mb-12">Skills & Experiences</h2>

                <div className="flex flex-col lg:flex-row items-start justify-between">
                    {/* Skills Section */}
                    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6 lg:w-1/2">
                        {/* GitHub */}
                        <div className="text-center">
                            <div className="p-4 rounded-full bg-red-100 inline-block">
                                <FaGithub className="text-3xl text-gray-700" />
                            </div>
                            <p className="mt-2 text-sm">GitHub</p>
                        </div>
                        {/* Kubernetes */}
                        <div className="text-center">
                            <div className="p-4 rounded-full bg-red-100 inline-block">
                                <SiKubernetes className="text-3xl text-gray-700" />
                            </div>
                            <p className="mt-2 text-sm">Kubernetes</p>
                        </div>
                        {/* Docker */}
                        <div className="text-center">
                            <div className="p-4 rounded-full bg-red-100 inline-block">
                                <FaDocker className="text-3xl text-gray-700" />
                            </div>
                            <p className="mt-2 text-sm">Docker</p>
                        </div>
                        {/* AWS */}
                        <div className="text-center">
                            <div className="p-4 rounded-full bg-red-100 inline-block">
                                <FaAws className="text-3xl text-gray-700" />
                            </div>
                            <p className="mt-2 text-sm">AWS</p>
                        </div>
                        {/* Python */}
                        <div className="text-center">
                            <div className="p-4 rounded-full bg-red-100 inline-block">
                                <FaPython className="text-3xl text-gray-700" />
                            </div>
                            <p className="mt-2 text-sm">Python</p>
                        </div>
                        {/* Prometheus */}
                        <div className="text-center">
                            <div className="p-4 rounded-full bg-red-100 inline-block">
                                <SiPrometheus className="text-3xl text-gray-700" />
                            </div>
                            <p className="mt-2 text-sm">Prometheus</p>
                        </div>
                        {/* Grafana */}
                        <div className="text-center">
                            <div className="p-4 rounded-full bg-red-100 inline-block">
                                <SiGrafana className="text-3xl text-gray-700" />
                            </div>
                            <p className="mt-2 text-sm">Grafana</p>
                        </div>
                        {/* GitLab */}
                        <div className="text-center">
                            <div className="p-4 rounded-full bg-red-100 inline-block">
                                <SiGitlab className="text-3xl text-gray-700" />
                            </div>
                            <p className="mt-2 text-sm">GitLab</p>
                        </div>
                        {/* Linux */}
                        <div className="text-center">
                            <div className="p-4 rounded-full bg-red-100 inline-block">
                                <FaLinux className="text-3xl text-gray-700" />
                            </div>
                            <p className="mt-2 text-sm">Linux</p>
                        </div>
                        {/* Terraform */}
                        <div className="text-center">
                            <div className="p-4 rounded-full bg-red-100 inline-block">
                                <SiTerraform className="text-3xl text-gray-700" />
                            </div>
                            <p className="mt-2 text-sm">Terraform</p>
                        </div>
                        {/* Ansible */}
                        <div className="text-center">
                            <div className="p-4 rounded-full bg-red-100 inline-block">
                                <SiAnsible className="text-3xl text-gray-700" />
                            </div>
                            <p className="mt-2 text-sm">Ansible</p>
                        </div>
                        {/* SQL */}
                        <div className="text-center">
                            <div className="p-4 rounded-full bg-red-100 inline-block">
                                <SiMysql className="text-3xl text-gray-700" />
                            </div>
                            <p className="mt-2 text-sm">SQL</p>
                        </div>
                        {/* NoSQL */}
                        <div className="text-center">
                            <div className="p-4 rounded-full bg-red-100 inline-block">
                                <SiMongodb className="text-3xl text-gray-700" />
                            </div>
                            <p className="mt-2 text-sm">NoSQL</p>
                        </div>
                    </div>

                    {/* Experiences Section */}
                    <div className="lg:w-1/2 mt-12 lg:mt-0">
                        <div className="space-y-8">
                            {/* Expérience 1 */}
                            <div
                                onMouseEnter={() => setHoveredExperience(1)}
                                onMouseLeave={() => setHoveredExperience(null)}
                                className="relative"
                            >
                                <h3 className="text-blue-500 font-bold text-lg">2023</h3>
                                <p className="text-xl font-semibold">Développeur web / DevOps</p>
                                <p className="text-gray-500">Freelance (3 ans)</p>

                                {hoveredExperience === 1 && (
                                    <div className="absolute left-96 -mt-4 p-4 bg-gray-100 text-gray-700 rounded shadow-lg w-72 text-sm leading-relaxed">
                                        <p className="mb-4">Création de site pour une entreprise de forgerons,
                                            spécialisée dans la taillanderie, avec maintenance et automatisation
                                            complètes.</p>
                                        <p className="mb-4">Développement d'une application web en partenariat avec un
                                            studio de jeux vidéo, déployée dans le cloud. Automatisation avec GitLabCI,
                                            mise à jour et intégration avec un bot Discord pour notifier les joueurs
                                            (Python).</p>
                                        <p className="mb-4">Création de site pour une entreprise de froid et
                                            climatisation (Forez Clim) avec maintenance mensuelle.</p>
                                        <p className="mb-4">Développement d'une plateforme de vente en ligne pour
                                            l'équipe esport ELEVEN avec Vue.js, et gestion du trafic via un Loadbalancer
                                            NGINX pour une haute disponibilité.</p>
                                        <p className="mb-4">Création d'une application de recherche de pièces motos,
                                            déployée sur Netlify, avec automatisation GitLabCI, provisioning via
                                            Terraform, tests avec Pytest, et monitoring avec Prometheus et Grafana.</p>
                                    </div>
                                )}
                            </div>

                            {/* Expérience 2 */}
                            <div
                                onMouseEnter={() => setHoveredExperience(2)}
                                onMouseLeave={() => setHoveredExperience(null)}
                                className="relative"
                            >
                                <h3 className="text-blue-500 font-bold text-lg">2021-2022</h3>
                                <p className="text-xl font-semibold">Intervenant Développeur Web & Data</p>
                                <p className="text-gray-500">ESD Paris (1 an)</p>

                                {hoveredExperience === 2 && (
                                    <div className="absolute left-96 -mt-4 p-4 bg-gray-100 text-gray-700 rounded shadow-lg w-72 text-sm leading-relaxed">
                                        <p>Enseignement de concepts de développement web et data, avec un focus sur les
                                            technologies modernes comme React, Node.js, et l'intégration continue.</p>
                                    </div>
                                )}
                            </div>

                            {/* Expérience 3 */}
                            <div
                                onMouseEnter={() => setHoveredExperience(3)}
                                onMouseLeave={() => setHoveredExperience(null)}
                                className="relative"
                            >
                                <h3 className="text-blue-500 font-bold text-lg">2019-2021</h3>
                                <p className="text-xl font-semibold">Data Analyst</p>
                                <p className="text-gray-500">CNH Industrial (2 ans)</p>

                                {hoveredExperience === 3 && (
                                    <div
                                        className="absolute left-96 -mt-6 p-4 bg-gray-100 text-gray-700 rounded shadow-lg w-72 text-sm leading-relaxed">
                                        <p className="mb-4">Gestion des bases de données : Création, gestion et
                                            maintenance des bases de données pour stocker des données opérationnelles et
                                            analytiques. Mise en place de processus d'extraction, transformation et
                                            chargement (ETL) pour assurer l'intégrité des données.</p>
                                        <p className="mb-4">Analyse de données : Extraction et analyse des données de
                                            différentes sources pour identifier les tendances et proposer des
                                            recommandations. Utilisation de SQL et de Python pour manipuler les données
                                            et produire des rapports approfondis.</p>
                                        <p className="mb-4">Création de dashboards : Conception et déploiement de
                                            tableaux de bord interactifs pour les équipes internes, permettant un suivi
                                            en temps réel des KPIs. Utilisation d'outils comme Power BI et Excel pour la
                                            création de visualisations personnalisées adaptées aux besoins métiers.</p>
                                    </div>
                                )}
                            </div>

                            {/* Expérience 4 */}
                            <div
                                onMouseEnter={() => setHoveredExperience(4)}
                                onMouseLeave={() => setHoveredExperience(null)}
                                className="relative"
                            >
                                <h3 className="text-blue-500 font-bold text-lg">2018-2019</h3>
                                <p className="text-xl font-semibold">Développeur site E-Commerce</p>
                                <p className="text-gray-500">Freelance (1 an)</p>

                                {hoveredExperience === 4 && (
                                    <div
                                        className="absolute left-96 top-1/2 p-4 bg-gray-100 text-gray-700 rounded shadow-lg w-72 text-sm leading-relaxed">
                                        <p>Développement de sites e-commerce avec intégration de systèmes de paiement et
                                            gestion des bases de données SQL et NoSQL.</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Skills;
