import React, { useState } from 'react';

const projects = [
    {
        id: 1,
        category: 'Devops',
        title: 'MyPartMoto',
        description: 'Application de recherche de pièces motos automatisée, utilisation de Netlify, automatisation avec GitLabCI, approvisionnement de la structure avec Terraform, tests unitaires et intégration avec Pytest. Surveillance et analyse des performances avec Prometheus et Grafana.',
        image: 'path_to_image_1',
        technologies: ['Kubernetes', 'Docker', 'AWS', 'Python', 'NGINX', 'GCP', 'Prometheus', 'Grafana']
    },
    {
        id: 2,
        category: 'Web',
        title: 'Divers Sites internets réalisés',
        description: 'Création de site avec plateforme de vente en ligne de produits\n' +
            'avec maintenance hebdomadaire équipe esport ELEVEN,\n' +
            'Utilisation de Vue.js et automatisation avec Loadbalancer serveur\n' +
            'NGINX pour une autre disponibilité et trafic chargé.\n',
        image: 'path_to_image_2',
        technologies: ['Ansible', 'AWS', 'Kubernetes', 'Docker', 'AWS', 'Python', 'NGINX', 'React']
    },
    {
        id: 3,
        category: 'Cours',
        title: 'Cours Data',
        description: 'Contactez moi plus plus d informations.',
        image: 'path_to_image_3',
        technologies: ['GitLab CI', 'React', 'Docker']
    }
];

const Work = () => {
    const [activeCategory, setActiveCategory] = useState('All');

    const filteredProjects = activeCategory === 'All' ? projects : projects.filter(project => project.category === activeCategory);

    return (
        <section id="work" className="py-16 bg-gray-100 min-h-screen">
            <div className="container mx-auto">
                <h2 className="text-4xl font-bold text-center mb-12">Projects</h2>

                {/* Catégories */}
                <div className="flex justify-center space-x-4 mb-8">
                    <button onClick={() => setActiveCategory('All')}
                            className={`py-2 px-4 ${activeCategory === 'All' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-black'} rounded`}>
                        All
                    </button>
                    <button onClick={() => setActiveCategory('Devops')}
                            className={`py-2 px-4 ${activeCategory === 'Devops' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-black'} rounded`}>
                        DevOps
                    </button>
                    <button onClick={() => setActiveCategory('Web')}
                            className={`py-2 px-4 ${activeCategory === 'Web' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-black'} rounded`}>
                        Web
                    </button>
                    <button onClick={() => setActiveCategory('Cours')}
                            className={`py-2 px-4 ${activeCategory === 'Cours' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-black'} rounded`}>
                        Cours
                    </button>
                    <button onClick={() => setActiveCategory('React JS')}
                            className={`py-2 px-4 ${activeCategory === 'React JS' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-black'} rounded`}>
                        Divers
                    </button>
                </div>

                {/* Projets */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                    {filteredProjects.map(project => (
                        <div key={project.id} className="bg-white rounded-lg shadow-md p-4 h-full">
                            <img src={project.image} alt={project.title}
                                 className="h-48 w-full object-cover rounded-lg"/>
                            <h3 className="text-lg font-bold mt-4">{project.title}</h3>
                            <p className="text-gray-500 mt-2">{project.description}</p>
                            <div className="mt-4">
                                <p className="font-semibold">Technologies :</p>
                                <ul className="flex flex-wrap space-x-2 mt-2">
                                    {project.technologies.map(tech => (
                                        <li key={tech}
                                            className="text-sm bg-gray-200 rounded px-2 py-1 mb-2">{tech}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Work;
